import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { CircularProgress, Switch } from "@material-ui/core";
import { FormControlLabel } from "@mui/material";
import {
  Button,
  Flex,
  MultipleSelect,
  Tooltip,
  Typography,
} from "../../../components";

import { infoCircleIcon } from "../../../assets";
import { sortArray } from "../../../helpers";
import api, { END_POINTS } from "../../../services/api";
import { amountOption, timeOption } from "./helper";

import {
  EAvailableFields,
  TFilterFormValues,
  TFilterOptions,
  TQuestionFilterProps,
} from "./types";
import * as S from "./styles";

export const QuestionFilter = ({
  fieldToShow,
  requiredFields,
  isFlashCard = false,
  showNotFound = false,
  isLoadingFilter,
  onSubmit,
}: TQuestionFilterProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [filterOptions, setFilterOptions] = useState<TFilterOptions>();
  const [matterOptions, setMatterOptions] = useState<Array<string>>([]);
  const [open, setOpen] = useState<boolean>(false);

  const initialValues: TFilterFormValues = {
    courses: [],
    matters: [],
    banks: [],
    years: [],
    difficulties: [],
    amount: "",
    time: "",
    hide_answered: false,
  };

  useEffect(() => {
    const handleQuestion = async () => {
      try {
        const filter = await api.get<TFilterOptions>(
          `${END_POINTS.filterQuestions}?flashcard=${isFlashCard}`
        );

        setFilterOptions(filter.data);
      } finally {
        setIsLoading(false);
      }
    };

    if (!filterOptions) handleQuestion();
  }, []);

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: (data) => onSubmit(data),
  });

  const requiredFieldsFilled = () => {
    if (requiredFields) {
      return requiredFields.every((field) => {
        const value = formik.values[field];
        return typeof value === "string"
          ? !!value.trim()
          : Array.isArray(value) && value.length > 0;
      });
    }
    return true;
  };

  const isRequired = (field: EAvailableFields) =>
    requiredFields?.includes(field) ? "*" : "";

  useEffect(() => {
    if (filterOptions?.courses) {
      let values = [];
      for (const key of formik.values.courses) {
        values.push(...filterOptions.courses[key]);
      }

      const uniqueValues = values.filter((value, index, self) => {
        return self.indexOf(value) === index;
      });
      setMatterOptions(uniqueValues);
    }

    if (formik.values.courses.length === 0) {
      formik.setFieldValue(EAvailableFields.MATTERS, initialValues.matters);
    }
  }, [formik.values.courses]);

  const handleResetForm = () => {
    formik.resetForm();
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const handleTooltipClose = () => {
    setOpen(false);
  };

  return (
    <S.Container>
      {isLoading || !filterOptions ? (
        <Flex justifyContent="center">
          <CircularProgress />
        </Flex>
      ) : (
        <S.Form onSubmit={formik.handleSubmit} noValidate>
          <S.Content>
            {fieldToShow.includes(EAvailableFields.COURSES) && (
              <MultipleSelect
                label={`Disciplina ${isRequired(EAvailableFields.COURSES)}`}
                name={EAvailableFields.COURSES}
                options={sortArray(Object.keys(filterOptions?.courses ?? []))}
                formik={formik}
              />
            )}

            {fieldToShow.includes(EAvailableFields.MATTERS) && (
              <MultipleSelect
                label={`Assunto ${isRequired(EAvailableFields.MATTERS)}`}
                name={EAvailableFields.MATTERS}
                options={sortArray(matterOptions)}
                formik={formik}
              />
            )}

            {fieldToShow.includes(EAvailableFields.BANKS) && (
              <MultipleSelect
                label={`Banca ${isRequired(EAvailableFields.BANKS)}`}
                name={EAvailableFields.BANKS}
                options={filterOptions.banks}
                formik={formik}
                upperCase
              />
            )}

            {fieldToShow.includes(EAvailableFields.YEARS) && (
              <MultipleSelect
                label={`Ano ${isRequired(EAvailableFields.YEARS)}`}
                name={EAvailableFields.YEARS}
                options={filterOptions.years}
                formik={formik}
              />
            )}

            {fieldToShow.includes(EAvailableFields.DIFFICULTIES) && (
              <MultipleSelect
                label={`Dificuldade ${isRequired(
                  EAvailableFields.DIFFICULTIES
                )}`}
                name={EAvailableFields.DIFFICULTIES}
                options={filterOptions.difficulties}
                formik={formik}
              />
            )}

            {fieldToShow.includes(EAvailableFields.AMOUNT) && (
              <MultipleSelect
                label={`Quantidade de cards ${isRequired(
                  EAvailableFields.AMOUNT
                )}`}
                name={EAvailableFields.AMOUNT}
                options={amountOption}
                formik={formik}
                acceptOne
              />
            )}

            {fieldToShow.includes(EAvailableFields.TIME) && (
              <MultipleSelect
                label={`Tempo ${isRequired(EAvailableFields.TIME)}`}
                name={EAvailableFields.TIME}
                options={timeOption}
                formik={formik}
                acceptOne
              />
            )}

            <S.CheckboxContainer>
              <Flex>
                {fieldToShow.includes(EAvailableFields.HIDE_ANSWERED) && (
                  <FormControlLabel
                    control={
                      <Switch
                        name={EAvailableFields.HIDE_ANSWERED}
                        checked={formik.values.hide_answered}
                        onChange={formik.handleChange}
                      />
                    }
                    label="Ocultar questões já respondidas"
                  />
                )}
                <Tooltip
                  title="Ao selecionar, não serão exibidas as questões respondidas anteriormente."
                  open={open}
                  onClose={handleTooltipClose}
                  onOpen={handleTooltipOpen}
                  disableFocusListener
                >
                  <img
                    src={infoCircleIcon}
                    onClick={handleTooltipOpen}
                    onMouseLeave={handleTooltipClose}
                  />
                </Tooltip>
              </Flex>
            </S.CheckboxContainer>
          </S.Content>

          <Flex gap={16} style={{ padding: "0 2vh" }} width="100%">
            {showNotFound && !!formik.submitCount && !isLoadingFilter && (
              <Flex direction="column">
                <Typography color="#FF8181" weight={500}>
                  Tente outra combinação.
                </Typography>
                <Typography color="#FF8181" variant="small">
                  Não foram encontradas questões para o filtro selecionado.
                </Typography>
              </Flex>
            )}

            <Flex gap={8} style={{ marginLeft: "auto" }}>
              <Button
                size="small"
                isSecondary
                type="reset"
                onClick={handleResetForm}
              >
                Limpar
              </Button>

              <Button
                type="submit"
                size="small"
                disabled={!requiredFieldsFilled() || isLoading}
                isLoading={isLoadingFilter}
              >
                Filtrar
              </Button>
            </Flex>
          </Flex>
        </S.Form>
      )}
    </S.Container>
  );
};
