import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom-v5-compat";
import {
  EAvailableFields,
  TFilterFormValues,
} from "../../components/organisms/QuestionFilter/types";

import api, { END_POINTS } from "../../services/api";

import { AuthContext } from "../../contexts/auth";
import { TAnsweredQuestionResponse, TLocalAnswered } from "./type";
import { TQuestion } from "../../contexts/question";
import { ERoutesPath } from "../../routes";
import {
  PageTemplate,
  QuestionFilter,
  Question,
  Flex,
  Pagination,
} from "../../components";

export const Questions: React.FC = () => {
  const FILTER_OPTIONS = [
    EAvailableFields.COURSES,
    EAvailableFields.MATTERS,
    EAvailableFields.BANKS,
    EAvailableFields.YEARS,
    EAvailableFields.DIFFICULTIES,
    EAvailableFields.TIME,
    EAvailableFields.HIDE_ANSWERED,
  ];

  const navigate = useNavigate();
  const { isPaidActive, user } = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState(false);
  const [offset, setOffset] = useState(0);

  const [questions, setQuestions] = useState<Array<TQuestion>>();
  const [questionsToShow, setQuestionsToShow] = useState<Array<TQuestion>>();
  const [previousAnswers, setPreviousAnswers] = useState<TLocalAnswered>();

  const idAccount = user.id_user;
  const ITEMS_PER_PAGE = 10;

  useEffect(() => {
    if (isPaidActive) {
      handleGetAnsweredQuestions();
    } else {
      navigate(ERoutesPath.ROOT);
    }
  }, []);

  useEffect(() => {
    setQuestionsToShow(
      questions && questions.slice(offset, offset + ITEMS_PER_PAGE)
    );
  }, [questions, offset]);

  const handleGetAnsweredQuestions = async () => {
    try {
      const listQuestions = await api.get(
        `${END_POINTS.lastQuestionsAnswered}?idAccount=${idAccount}`
      );
      const answeredObj: TLocalAnswered = listQuestions.data.reduce(
        (acc: TLocalAnswered, curr: TAnsweredQuestionResponse) => ({
          ...acc,
          [curr.id_question]: curr.correct,
        }),
        {}
      );
      setPreviousAnswers(answeredObj);
    } catch {
      console.error("Erro ao buscar questões respondidas");
    }
  };

  const handleFilter = async (data: TFilterFormValues) => {
    try {
      setQuestions(undefined);
      setOffset(0);
      setIsLoading(true);
      const filteredQuestions = await api.post<Array<TQuestion>>(
        END_POINTS.questionByFilter,
        { ...data, id_account: idAccount }
      );
      setQuestions(filteredQuestions.data);
    } catch (error) {
      alert(
        "Desculpe. Não foi possível carregar as questões, tente novamente mais tarde..."
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <PageTemplate title="Resolver questões">
      <Flex direction="column" gap={16}>
        <QuestionFilter
          fieldToShow={FILTER_OPTIONS}
          onSubmit={handleFilter}
          showNotFound={questions && questions.length === 0}
          isLoadingFilter={isLoading}
        />

        {questions && questions.length > 0 && (
          <Flex direction="column" gap={16}>
            {questionsToShow?.map((question, key) => (
              <Question
                data={question}
                key={question.id}
                number={offset + key + 1}
                isPreviousAnswerCorrect={
                  previousAnswers ? previousAnswers[question.id] : undefined
                }
                allowRedo
              />
            ))}

            <Flex width="100%" justifyContent="flex-end">
              <Pagination
                itemsPerPage={ITEMS_PER_PAGE}
                totalItems={questions.length}
                offset={offset}
                setOffset={setOffset}
              />
            </Flex>
          </Flex>
        )}
      </Flex>
    </PageTemplate>
  );
};
