import { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom-v5-compat";

import {
  QuestionFilter,
  Typography,
  EditQuestion,
  PageTemplate,
} from "../../components";
import {
  EAvailableFields,
  TFilterFormValues,
} from "../../components/organisms/QuestionFilter/types";
import api, { END_POINTS } from "../../services/api";
import { AuthContext } from "../../contexts/auth";
import { ERoutesPath } from "../../routes";

import * as S from "./styles";
import { TFlashData } from "./types";

export const FlashCardPage = () => {
  const FILTER_OPTIONS = [
    EAvailableFields.COURSES,
    EAvailableFields.MATTERS,
    EAvailableFields.BANKS,
    EAvailableFields.DIFFICULTIES,
    EAvailableFields.AMOUNT,
  ];

  const [isLoading, setIsLoading] = useState(false);
  const [questions, setQuestions] = useState<Array<TFlashData>>();
  const [showResponse, setShowResponse] = useState<boolean>(false);
  const [activeQuestion, setActiveQuestion] = useState<number>(0);
  const [answer, setAnswer] = useState<"A" | "B" | undefined>(undefined);

  const navigate = useNavigate();
  const { isPaidActive, isUserLogged } = useContext(AuthContext);

  useEffect(() => {
    if (!isUserLogged || !isPaidActive) {
      navigate(ERoutesPath.ROOT);
    }
  }, []);

  const handleFilter = async (data: TFilterFormValues) => {
    try {
      setQuestions(undefined);
      setIsLoading(true);
      const flashCardQuestions = await api.post<Array<TFlashData>>(
        END_POINTS.flashcards,
        data
      );
      setQuestions(flashCardQuestions.data);
    } catch (error) {
      navigate(ERoutesPath.ROOT);
    } finally {
      setIsLoading(false);
    }
  };

  const handleShowResponse = (value: "A" | "B") => {
    setAnswer(value);
    setShowResponse(true);
  };

  const hasMoreQuestion = useMemo(() => {
    if (questions) {
      return questions.length > activeQuestion + 1;
    }
  }, [questions, activeQuestion]);

  const handleNextQuestion = () => {
    setShowResponse(false);
    if (hasMoreQuestion) setActiveQuestion(activeQuestion + 1);
  };

  const isCorrectAnswer = () =>
    questions && questions[activeQuestion].feedback === answer;

  const cleanData = () => {
    setQuestions(undefined);
    setActiveQuestion(0);
    setShowResponse(false);
  };

  return (
    <PageTemplate
      title="Revise rápido"
      subTitle="Com todas as sentenças diretas das provas anteriores"
    >
      {!questions?.length && (
        <QuestionFilter
          fieldToShow={FILTER_OPTIONS}
          requiredFields={[EAvailableFields.AMOUNT]}
          onSubmit={handleFilter}
          isFlashCard
          showNotFound={questions && questions.length === 0}
          isLoadingFilter={isLoading}
        />
      )}

      {questions && questions.length > 0 && (
        <>
          <EditQuestion question={questions[activeQuestion]} />

          <S.Content>
            {showResponse ? (
              <S.Card isTrue={isCorrectAnswer()}>
                <Typography variant="upper" align="center">
                  {questions[activeQuestion].feedback === "A"
                    ? "Verdadeiro"
                    : "Falso"}
                </Typography>

                <Typography variant="title" align="center" weight={500}>
                  {questions[activeQuestion].comment_correction}
                </Typography>
              </S.Card>
            ) : (
              <S.Card>
                <div>
                  <S.CenterCounter>
                    <S.ImgCounter>
                      <Typography variant="subTitle">
                        {activeQuestion + 1}
                      </Typography>
                    </S.ImgCounter>
                  </S.CenterCounter>
                  <Typography variant="title" align="center">
                    {questions[activeQuestion].description}
                  </Typography>
                </div>
              </S.Card>
            )}

            <S.Actions>
              {showResponse ? (
                <>
                  {hasMoreQuestion ? (
                    <S.Button color="#FFC440" onClick={handleNextQuestion}>
                      <Typography variant="subTitle">Próximo</Typography>
                    </S.Button>
                  ) : (
                    <S.Content>
                      <Typography>
                        Parabéns, você respondeu todos os cards disponíveis para
                        a sua seleção!
                      </Typography>

                      <S.Button color="#3A66FB" onClick={cleanData}>
                        <Typography variant="subTitle" color="white">
                          Voltar
                        </Typography>
                      </S.Button>
                    </S.Content>
                  )}
                </>
              ) : (
                <>
                  <S.Button
                    color="#7ED956"
                    onClick={() => handleShowResponse("A")}
                  >
                    <Typography variant="subTitle">Verdadeiro</Typography>
                  </S.Button>

                  <S.Button
                    color="#C54B47"
                    onClick={() => handleShowResponse("B")}
                  >
                    <Typography variant="subTitle">Falso</Typography>
                  </S.Button>
                </>
              )}
            </S.Actions>
          </S.Content>
        </>
      )}
    </PageTemplate>
  );
};
